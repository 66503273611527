<!--
 * @Description: 
 * @Autor: fzw
 * @Date: 2024-07-17 18:47:17
 * @LastEditTime: 2024-09-30 14:45:02
-->
<template>
  <el-container>
    <!-- 页头 -->
    <el-header>
      <el-row class="row-bg" type="flex" align="middle" justify="space-between">
        <!-- 头部左 -->
        <div class="logo"></div>
        <!-- 头部右 -->
        <div>
          <el-space :size="24">
            <el-popover
              placement="bottom"
              trigger="click"
              @before-enter="popoverVisible = true"
              @before-leave="popoverVisible = false"
              popper-class="userinfoPopover"
              :show-arrow="false"
              :teleported="false"
            >
              <div class="custom-content">
                <div class="user-info">
                  <el-avatar
                    v-if="PublicStores.UserInfo.UserType == 0"
                    :size="48"
                    src="/image/home/MedicBig.png"
                  />
                  <el-avatar
                    v-if="PublicStores.UserInfo.UserType == 1"
                    :size="48"
                    src="/image/home/artificerBig.png"
                  />
                  <span class="NickName">{{
                    PublicStores.UserInfo.NickName
                  }}</span>
                  <span>{{ PublicStores.UserInfo.Email }}</span>
                </div>
                <el-button
                  type="primary"
                  class="accountSettingBtn flexSpaceBett"
                  @click="getUserInfo"
                  ><img src="/image/home/setting.png" /><span>{{
                    t("Account Setting")
                  }}</span></el-button
                >
                <el-button
                  type="default"
                  class="signOutBtn flexSpaceBett"
                  @click="exit"
                  ><img src="/image/home/SignOut.png" /><span>{{
                    t("Sign Out")
                  }}</span>
                </el-button>
              </div>
              <template #reference>
                <div
                  class="avatarBox"
                  :style="`${popoverVisible ? 'background: #e9eaee;' : ''}`"
                >
                  <el-avatar
                    v-if="PublicStores.UserInfo.UserType == 0"
                    :size="32"
                    src="/image/home/Medic.png"
                  />
                  <el-avatar
                    v-if="PublicStores.UserInfo.UserType == 1"
                    :size="32"
                    src="/image/home/artificer.png"
                  />
                  <span v-textTootip:auto>{{
                    PublicStores.UserInfo.NickName
                  }}</span>
                </div>
              </template>
            </el-popover>
            <span class="custom-spacer">|</span>
            <tip-icom
              iconClass="icon40"
              :url="
                (InviteData.some((item) => item.Status == '0') &&
                  PublicStores.UserInfo.UserType == 1) ||
                (messageTipState >= 1 && PublicStores.UserInfo.UserType == 1)
                  ? '/image/home/APartnerSettings.png'
                  : '/image/home/PartnerSettings.png'
              "
              :tip="t('Invite Partner')"
              @click="openInviteList"
            ></tip-icom>
            <span class="custom-spacer">|</span>
            <div class="IcomBox">
              <tipIcom
                v-if="locale == 'en'"
                iconClass="icon40"
                url="/image/home/Chinese.png"
                :tip="t('Language Switch')"
                @click="languageSwitch"
              ></tipIcom>
              <tipIcom
                v-if="locale == 'zh'"
                iconClass="icon40"
                url="/image/home/English.png"
                :tip="t('Language Switch')"
                @click="languageSwitch"
              ></tipIcom>
              <tipIcom
                iconClass="icon40"
                url="/image/home/assist.png"
                :tip="t('Help')"
              ></tipIcom>
              <tipIcom
                iconClass="icon40"
                url="/image/home/about.png"
                :tip="t('About')"
              ></tipIcom>
            </div>
          </el-space>
        </div>
      </el-row>
    </el-header>
    <el-main>
      <div class="main">
        <!-- 内容区头部 -->
        <div class="headerContent">
          <h3>{{ t("Order List") }}</h3>
          <div class="searchBox">
            <div class="featureIcon">
              <tipIcom
                iconClass="icon32"
                :tip="t('multi Select')"
                @click="multiSelect"
                :url="
                  !ifSelectionColumn
                    ? '/image/home/checkbox.png'
                    : '/image/home/Acheckbox.png'
                "
              ></tipIcom>
              <tipIcom
                iconClass="icon32"
                url="/image/home/calendar.png"
                :tip="t('Select Time')"
              >
                <date-picker
                  v-model:value="datePickerValue"
                  range
                  popup-class="datePicker"
                  :lang="lang"
                  @change="handleSearchClick"
                >
                </date-picker>
              </tipIcom>
            </div>
            <el-input
              v-model="searchValue"
              size="large"
              :placeholder="t('Please Enter Search Content')"
              v-debounce:keydown.enter="{
                handler: handleSearchClick,
                delay: 300,
              }"
            >
              <template #suffix>
                <img
                  style="cursor: pointer"
                  src="/image/home/search.png"
                  v-debounce:click="{ handler: handleSearchClick, delay: 300 }"
                />
              </template>
            </el-input>
            <el-button
              class="cancelOrderBtn"
              style="margin-left: 10px"
              @click="ClearSearch"
              >清空</el-button
            >
          </div>
          <div>
            <el-button
              class="cancelOrderBtn"
              type="default"
              @click="cancelOrder"
              v-limit="4"
            >
              <img src="/image/home/cancel.png" /><span>
                {{ t("Cancel Order") }}</span
              ></el-button
            >
          </div>
        </div>
        <!-- 表单列表 -->
        <div style="flex: 1; position: relative">
          <div style="position: absolute; width: 100%; height: 100%">
            <el-table
              ref="orderTable"
              @expand-change="expandColumn"
              :row-key="getRowKeys"
              :expand-row-keys="expands"
              :data="tableData"
              stripe
              height="100%"
              class-name="orderTable"
              :header-cell-style="{ background: ' #B2CF76', color: '#fff' }"
              @selection-change="selectCheck"
              :row-class-name="
                ({ row }) =>
                  selectCheckData.includes(row) ? 'highlight-row' : ''
              "
            >
              <el-table-column
                v-if="ifSelectionColumn"
                type="selection"
                width="55"
              />
              <el-table-column prop="PatientName" show-overflow-tooltip>
                <template #header>
                  <span
                    class="tableHeader"
                    style="display: inline-block; width: 100%"
                    v-textTootip:auto
                    >{{ t("Patient Name") }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column prop="CreatedAt" show-overflow-tooltip>
                <template #header>
                  <span>{{ t("Send Time") }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="DoctorAddrName" show-overflow-tooltip>
                <template #header>
                  <span>{{ t("Clinic") }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="MechanicAddrName" show-overflow-tooltip>
                <template #header>
                  <span>{{ t("Workshop") }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="OrderStatus"
                filter-placement="bottom-end"
                show-overflow-tooltip
              >
                <template #header>
                  <span>{{ t("Order Status") }}</span>
                </template>
                <template #default="scope">
                  <div>
                    <img
                      class="OrderStatusIcon"
                      v-if="scope.row.OrderStatus == 2"
                      src="/image/home/submit.png"
                    />
                    <img
                      class="OrderStatusIcon"
                      v-if="scope.row.OrderStatus == 3"
                      src="/image/home/Downloaded.png"
                    />
                    <img
                      class="OrderStatusIcon"
                      v-if="scope.row.OrderStatus == 0"
                      src="/image/home/Canceled.png"
                    />
                    <span>
                      {{ getOrderStatusText(scope.row.OrderStatus) }}</span
                    >
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="Indications" show-overflow-tooltip>
                <template #header>
                  <span>{{ t("Indication") }}</span>
                </template>
              </el-table-column>
              <el-table-column>
                <template #header>
                  <span>{{ t("Operation") }}</span>
                </template>
                <template #default="scope">
                  <el-row>
                    <div v-limit="1">
                      <tipIcom
                        iconClass="icon32right12"
                        :tip="t('Download File')"
                        url="/image/home/download.png"
                        @click="SaveFile(scope.row)"
                      ></tipIcom>
                    </div>
                    <div
                      v-limit="5"
                      v-if="
                        scope.row.OrderStatus != 3 && scope.row.OrderStatus != 0
                      "
                    >
                      <tipIcom
                        iconClass="icon32right12"
                        :tip="t('Upload File')"
                        url="/image/home/upload.png"
                        @click="handleUpload(scope.row)"
                      >
                        <input
                          ref="uploadInput"
                          class="upload-input"
                          type="file"
                          multiple
                          @change="(event) => handleClick(event, scope.row)"
                        />
                      </tipIcom>
                    </div>
                    <tipIcom
                      iconClass="icon32right12"
                      :tip="t('Shopping Cart')"
                      url="/image/home/shop.png"
                      @click="openShoppingCart(scope.row)"
                    ></tipIcom>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column width="50px">
                <template #default="scope">
                  <el-badge
                    v-if="scope.row.UnreadCount != 0"
                    :value="scope.row.UnreadCount"
                    :max="99"
                    class="messageTip"
                  ></el-badge>
                </template>
              </el-table-column>
              <el-table-column type="expand">
                <!-- 表格扩展 -->
                <template #default="{ row }">
                  <div class="expandBox">
                    <!-- 缩略图部分 -->
                    <div class="thumbnailBox">
                      <!-- 缩略图card -->
                      <div
                        class="thumbnailCard"
                        @click="selectThumbnailCard(item.id)"
                        @contextmenu="selectThumbnailCard(item.id)"
                        @dblclick="openModelPreview(row.fileInfo, item)"
                        @contextmenu.prevent="showMenu(item)"
                        v-for="item in row.fileInfo"
                        :key="item.id"
                      >
                        <div>
                          <div
                            class="picture"
                            :class="
                              ThumbnailCardActive == item.id
                                ? 'pictureActive'
                                : ''
                            "
                            :style="
                              item.kind == 'attachment'
                                ? `background:url(/image/home/FileEmpty.png) no-repeat center center;background-size: 64px !important;`
                                : `background:url(https://cambridgeweb.oss-accelerate.aliyuncs.com/${encodeURIComponent(
                                    item.url
                                  )}) no-repeat center center;`
                            "
                          >
                            <div
                              class="symbol3D"
                              v-if="item.kind == '3d'"
                            ></div>
                            <div
                              class="screenshot"
                              v-if="item.kind == 'screenshot'"
                            ></div>
                            <div
                              class="photo"
                              v-if="item.kind == 'photo'"
                            ></div>
                            <div
                              class="attachment"
                              v-if="item.kind == 'attachment'"
                            ></div>
                          </div>
                          <p>设备sn</p>
                          <p style="width: 100%" show-overflow-tooltip>
                            {{
                              moment(item.name, "YYYYMMDD-HH_mm_ss_SSS").format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- 聊天部分 -->
                    <div class="chatBox">
                      <div class="chatHeader">
                        {{ row.DoctorAddrName }} -
                        {{
                          PublicStores.UserInfo.UserID != row.UserID
                            ? row.DoctorName
                            : ""
                        }}
                      </div>
                      <div class="chatContent">
                        <div id="contentHeight">
                          <!-- 消息框 -->
                          <div
                            class="chatFragment"
                            v-for="item in row.ChatHistory"
                            :key="item._id"
                          >
                            <div
                              class="sendTime"
                              style="margin-bottom: 5px"
                              :style="
                                PublicStores.UserInfo.UserID != item.UserID
                                  ? ''
                                  : 'text-align: right'
                              "
                            >
                              <span>{{ item.Timestamp }}</span>
                            </div>
                            <div
                              :class="
                                PublicStores.UserInfo.UserID != item.UserID
                                  ? 'targetChat'
                                  : 'MyChat'
                              "
                            >
                              <el-avatar
                                :src="
                                  item.UserType
                                    ? '/image/home/artificer.png'
                                    : '/image/home/Medic.png'
                                "
                              />
                              <div
                                class="Content"
                                :style="
                                  PublicStores.UserInfo.UserID != item.UserID
                                    ? 'background: #fff'
                                    : 'background: #d9e7ba'
                                "
                              >
                                {{ item.Content }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="chatFooter">
                        <el-input
                          class="sendInput"
                          v-model="sendContent"
                          v-debounce:keyup.enter="{
                            handler: () => sendMessage(row),
                            delay: 300,
                          }"
                        >
                          <template #suffix>
                            <el-button
                              type="primary"
                              link
                              @click="sendMessage(row)"
                            >
                              {{ $t("Send") }}
                            </el-button>
                          </template>
                        </el-input>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 分页 -->
        <div class="pagination-container">
          <!-- Custom pagination display -->
          <span>{{ t("Total Pages") }}: {{ Math.ceil(total / 10) }}</span>
          <el-pagination
            :pager-count="11"
            layout="prev, pager, next"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="total"
            @current-change="handlePageChange"
          >
          </el-pagination>
          <!-- Custom go to page input -->
          <span>{{ t("To") }}</span>
          <el-input
            v-model="jumpPage"
            size="small"
            style="width: 50px; text-align: center"
            v-debounce:keydown.enter="{
              handler: jumpToPage,
              delay: 300,
            }"
          ></el-input>
          <span>{{ t("Page") }}</span>
          <el-button
            type="default"
            v-debounce:click="{
              handler: jumpToPage,
              delay: 300,
            }"
            >{{ t("Confirm") }}</el-button
          >
        </div>
      </div>
    </el-main>
  </el-container>
  <!-- stl预览弹窗 -->
  <el-dialog
    v-model="STLbrowseShow"
    destroy-on-close
    top="3%"
    class="ModelPreview"
    :title="$t('3Dpreview')"
    @close="PublicStores.changeLoadingShow(false)"
  >
    <STLbrowse :modelUrlArr="modelUrlArr" />
  </el-dialog>
  <!-- 2d图像预览 -->
  <el-dialog
    v-model="PreviewShow"
    destroy-on-close
    top="3%"
    class="PreviewDialog"
    :title="$t('2Dpreview')"
    @close="PublicStores.changeLoadingShow(false)"
  >
    <Preview :srcList="srcList"></Preview>
  </el-dialog>
  <!-- 账户设置 -->
  <el-dialog
    draggable
    v-model="userInfoShow"
    destroy-on-close
    :title="t('Account Setting')"
    top="4%"
    :modal="false"
    :close-on-click-modal="false"
    class="editAccountInfo"
    @close="closeUserInfoShow"
  >
    <div class="editAccountInfoContent">
      <el-form label-position="left" :model="editUserForm" inline>
        <el-form-item>
          <template #label>
            <span class="custom-label" v-textTootip:auto>
              {{ t("Doctor Name") }}
            </span>
          </template>
          <el-input v-model="editUserForm.doctorName" />
        </el-form-item>
        <el-form-item>
          <template #label>
            <span class="custom-label" v-textTootip:auto>
              {{ t("Clinic Name") }}
            </span>
          </template>
          <el-input v-model="editUserForm.clinic" />
        </el-form-item>
        <el-form-item>
          <template #label>
            <span class="custom-label" v-textTootip:auto>
              {{ t("address") }}
            </span>
          </template>
          <el-input v-model="editUserForm.address" />
        </el-form-item>
        <el-form-item>
          <template #label>
            <span class="custom-label" v-textTootip:auto>
              {{ t("Contact Number") }}
            </span>
          </template>
          <el-input v-model="editUserForm.phone" />
        </el-form-item>
        <el-form-item>
          <template #label>
            <span class="custom-label" v-textTootip:auto>
              {{ t("Email") }}
            </span>
          </template>
          <el-input v-model="editUserForm.email" readonly>
            <template #suffix>
              <div
                @click="showEditEmailDialog"
                class="flexMiddle editIcon"
              ></div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <template #label>
            <span class="custom-label" v-textTootip:auto>
              {{ t("Password") }}
            </span>
          </template>
          <el-input v-model="editUserForm.password" readonly>
            <template #suffix>
              <div
                @click="showEditPasswordDialog"
                class="flexMiddle editIcon"
              ></div>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-row type="flex" align="middle" justify="center">
        <el-button size="large" @click="userInfoShow = false"
          ><img src="/image/home/cancle.png" style="margin-right: 18px" />{{
            t("Cancel")
          }}</el-button
        >
        <el-button
          size="large"
          type="primary"
          v-debounce:click="{ handler: editAccount, delay: 300 }"
          ><img src="/image/home/save.png" />{{ t("Save") }}</el-button
        >
      </el-row>
    </template>
    <EditEmail
      :EditEmailInnerShow="EditEmailInnerShow"
      :EditEmailCodeState="EditEmailCodeState"
      @closeEditEmailShow="EditEmailInnerShow = false"
      @getUserInfo="getUserInfo"
    ></EditEmail>
    <EditPassword
      :EditPasswordInnerShow="EditPasswordInnerShow"
      @getUserInfo="getUserInfo"
      @closeEditUserPasswordShow="() => (EditPasswordInnerShow = false)"
    ></EditPassword>
  </el-dialog>
  <!-- 邀请设置 -->
  <el-dialog
    draggable
    v-model="InviteListShow"
    :title="
      PublicStores.UserInfo.UserType == 0
        ? t('Workshop Setting')
        : t('Clinic List')
    "
    top="5%"
    :modal="false"
    :close-on-click-modal="false"
    destroy-on-close
    class="InviteListDialog"
  >
    <!-- 邀请列表弹窗 -->
    <InviteList
      :InviteData="InviteData"
      @handleOpenDialog="sendMailShow = true"
      @openInviteList="openInviteList"
    ></InviteList>
    <!-- 发送邮件内部框 -->
    <sendMail
      :sendMailShow="sendMailShow"
      @closeSendMailShow="sendMailShow = false"
      @openInviteList="openInviteList"
    ></sendMail>
  </el-dialog>
  <!-- shopping的dialog框 -->
  <el-dialog
    v-model="shoppingDialogShow"
    destroy-on-close
    top="3%"
    class="shoppingDialog"
    :close-on-click-modal="false"
  >
    <div
      class="shoppingDialogContent"
      :style="
        productImage != null
          ? `background:url(https://cambridgeweb.oss-accelerate.aliyuncs.com/${encodeURIComponent(
              productImage.path
            )}) no-repeat center center;`
          : ''
      "
    ></div>
    <template #footer>
      <el-row type="flex" align="middle" justify="center">
        <el-button type="primary" @click="MerchandiseDownload(productImage)"
          ><img src="/image/home/shoppingDownload.png" />{{
            t("Download")
          }}</el-button
        >
      </el-row>
    </template>
  </el-dialog>
  <!-- 右键菜单 -->
  <div v-show="menuVisible" :style="menuStyle" class="custom-context-menu">
    <ul>
      <li @click="menuClick(1)">{{ $t("check") }}</li>
      <li @click="menuClick(2)">{{ $t("Download") }}</li>
    </ul>
  </div>
</template>
<script setup>
//组件库链接 https://github.com/mengxiong10/vue-datepicker-next?tab=readme-ov-file
import DatePicker from "vue-datepicker-next";
import "vue-datepicker-next/index.css";
import moment from "moment";
// stl渲染组件
import STLbrowse from "@/components/common/STLbrowse.vue";
import Preview from "@/components/home/Preview.vue";
import EditEmail from "@/components/home/EditEmail.vue";
import EditPassword from "@/components/home/EditPassword.vue";
import InviteList from "@/components/home/InviteList.vue";
import sendMail from "@/components/home/sendMail.vue";
import { PublicStore } from "@/tools/pinia/store.js";
import OSS from "ali-oss";
import { nextTick, onBeforeMount, reactive } from "vue";
const { t, locale } = useI18n();
const router = useRouter();
let PublicStores = PublicStore();
function getOrderStatusText(status) {
  switch (status) {
    case "0":
      return t("Cancelled");
    case "2":
      return t("Submitted");
    case "3":
      return t("Downloaded");
  }
}

onMounted(async () => {
  await getorderlist();
  await GetInviteList();
  document.addEventListener("click", hideMenu);
  if (PublicStores.WebSocketService == null) {
    PublicStores.changeWebSocketService();
    PublicStores.WebSocketService.connect();
    PublicStores.WebSocketService.addListener(ResponseWebSocket);
  } else {
    PublicStores.WebSocketService.addListener(ResponseWebSocket);
  }
});
function ResponseWebSocket(data) {
  switch (data.type) {
    case "OrderMsgUnreadUpdate":
      let RowIndex = tableData.findIndex(
        (item) => item.OrderID == data.msg.OrderID
      );
      if (expands.value[0] == tableData[RowIndex].OrderID) {
        tableData[RowIndex].UnreadCount = 0;
        GetChatHistory(tableData[RowIndex]);
        return;
      }
      tableData[RowIndex].UnreadCount = data.msg.Count;
      break;
    case "PartnerInvPending":
      messageTipState.value = data.msg.Count;
      break;
  }
}
// 头像popover的状态
let popoverVisible = ref(false);

// 语言切换逻辑处理
function languageSwitch() {
  if (locale.value == "zh") {
    locale.value = "en";
    PublicStores.changeLanguage(0);
  } else {
    locale.value = "zh";
    PublicStores.changeLanguage(1);
  }
  updatePickerDate();
}

// 条件查询input
let searchValue = ref("");

// 监视日期选择变化
let formatLocale = reactive({
  months: [],
  monthsShort: [],
  weekdays: [],
  weekdaysShort: [],
  weekdaysMin: [],
  firstDayOfWeek: 0,
  firstWeekContainsDate: 1,
});
let lang = reactive({
  formatLocale: formatLocale,
  yearFormat: "YYYY年",
  monthFormat: "MMM",
  monthBeforeYear: true,
});
updatePickerDate();
function updatePickerDate() {
  formatLocale.months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];
  (formatLocale.monthsShort = [
    t("Jan"),
    t("Feb"),
    t("Mar"),
    t("Apr"),
    t("May"),
    t("Jun"),
    t("Jul"),
    t("Aug"),
    t("Sep"),
    t("Oct"),
    t("Nov"),
    t("Dec"),
  ]),
    (formatLocale.weekdays = [
      t("Sunday"),
      t("Monday"),
      t("Tuesday"),
      t("Wednesday"),
      t("Thursday"),
      t("Friday"),
      t("Saturday"),
    ]);
  formatLocale.weekdaysShort = [
    t("Sun"),
    t("Mon"),
    t("Tue"),
    t("Wed"),
    t("Thu"),
    t("Fri"),
    t("Sat"),
  ];
  formatLocale.weekdaysMin = [
    t("Su"),
    t("Mo"),
    t("Tu"),
    t("We"),
    t("Th"),
    t("Fr"),
    t("Sa"),
  ];
  if (locale.value == "zh") {
    lang.yearFormat = "YYYY年";
    lang.monthFormat = "MMM";
    lang.monthBeforeYear = false;
  } else if (locale.value == "en") {
    lang.yearFormat = "YYYY";
    lang.monthFormat = "MMM";
    lang.monthBeforeYear = true;
  }
}

let datePickerValue = ref([]);
let startDate = "";
let endDate = "";

function handleSearchClick() {
  getorderlist();
}
function ClearSearch() {
  datePickerValue.value.length = 0;
  getorderlist();
}

// stl模型加载组件逻辑处理
let STLbrowseShow = ref(false);
let modelUrlArr = reactive([]);

// 2d图像预览数据处理逻辑
let PreviewShow = ref(false);
let srcList = reactive([]);

function openModelPreview(fileInfo, item) {
  modelUrlArr.length = 0;
  srcList.length = 0;
  let groupMap = fileInfo.find((ite) => ite.id === item.id);
  switch (item.type) {
    case "3d":
      modelUrlArr.push(...groupMap.data);
      PublicStores.changeLoadingShow(true);
      PublicStores.changeLoadingText(t("Loading"));
      STLbrowseShow.value = !STLbrowseShow.value;
      break;
    case "2d":
      if (item.kind != "attachment") {
        srcList.push(...groupMap.data);
        PreviewShow.value = !PreviewShow.value;
      }
      break;
  }
}
let ThumbnailCardActive = ref("");
function selectThumbnailCard(Active) {
  ThumbnailCardActive.value = Active;
}

// 表格显示
const tableData = reactive([]);
const orderTable = ref(null);

async function getorderlist() {
  let startTimestamp = "";
  let endTimestamp = "";
  if (datePickerValue.value.length != 0) {
    let startDate = new Date(datePickerValue.value[0]);
    let endDate = new Date(datePickerValue.value[1]);
    // 将结束时间设置为 23:59:59.999 本地时间
    startTimestamp = moment(startDate.getTime()).format("YYYY-MM-DD HH:mm");
    endTimestamp = moment(endDate.getTime())
      .endOf("day")
      .format("YYYY-MM-DD HH:mm");
  }
  let data = {
    Keyword: searchValue.value,
    Limit: pageSize.value,
    Page: currentPage.value,
    CreateTimeStart: startTimestamp == "" ? "" : startTimestamp,
    CreateTimeEnd: endTimestamp == "" ? "" : endTimestamp,
  };
  expands.value = [];
  let res = await getRequest("/dental/order/getOrderList", data);
  expands.value = [];
  tableData.length = 0;
  tableData.push(...res.data.info);
  total.value = Number(res.data.total);
}

// 表格多选处理
let selectCheckData = reactive([]);
function selectCheck(value) {
  selectCheckData.length = 0;
  selectCheckData.push(...value);
}
let ifSelectionColumn = ref(false);

// 多选模式
function multiSelect() {
  if (ifSelectionColumn.value) {
    orderTable.value.clearSelection();
    ifSelectionColumn.value = !ifSelectionColumn.value;
  } else {
    ifSelectionColumn.value = !ifSelectionColumn.value;
  }
}
// 配置表格只能单个展开
let expands = ref([]);
let expandColumn = (row, expandedRows) => {
  //expandedRows 存放页面中被展开行的数据 对应的数组就是 expand-row-keys
  //通过expandedRows长度来判断用户点击是展开还是折叠
  if (expandedRows.length) {
    //展开
    expands.value = []; //先干掉之前展开的行
    if (row) {
      expands.value.push(row.OrderID); //push新的行 (原理有点类似防抖)
      row.UnreadCount = 0;
      // 展开获取历史聊天记录
      GetChatHistory(row);
    }
    nextTick(() => {
      const contentHeight = document.getElementById("contentHeight");
      const chatContent = document.querySelector(".chatContent");
      chatContent.scrollTop = contentHeight.scrollHeight;
    });
  } else {
    expands.value = []; //折叠 就清空expand-row-keys对应的数组
  }
  if (expandedRows.length && row.fileInfo == undefined) {
    getRequest("/dental/order/getOrderFile", { OrderID: row.OrderID }).then(
      (res) => {
        if (
          Object.keys(res.data).length === 0 &&
          res.data.constructor === Object
        ) {
          return;
        }
        row.path = res.data.orderInfo;
        row.fileInfo = DataProcessing(res.data);
        productImage.value = row;
      }
    );
  }
};
let getRowKeys = (row) => {
  //row是当前行的数据
  //给每行绑定唯一的标识
  return row.OrderID;
};

// 扩展缩略图右键自定义菜单
const menuVisible = ref(false);
const menuStyle = ref({ top: "0px", left: "0px" });
let menuItem = null;
const showMenu = (item) => {
  menuItem = item;
  // 获取页面的宽度和高度
  const pageWidth = window.innerWidth;
  const pageHeight = window.innerHeight;

  // 设置菜单默认位置
  let top = event.clientY;
  let left = event.clientX;

  // 获取菜单元素的尺寸
  const menuElement = document.querySelector(".custom-context-menu");
  const menuWidth = menuElement?.offsetWidth || 200; // 假设菜单的宽度为200px
  const menuHeight = menuElement?.offsetHeight || 212; // 假设菜单的高度为212px

  // 调整菜单位置以防止溢出
  if (top + menuHeight > pageHeight) {
    top = pageHeight - menuHeight;
  }

  if (left + menuWidth > pageWidth) {
    left = pageWidth - menuWidth;
  }

  // 更新菜单位置
  menuStyle.value = {
    top: `${top}px`,
    left: `${left}px`,
  };

  menuVisible.value = true;
};
const hideMenu = () => {
  menuVisible.value = false;
};
const menuClick = async (index) => {
  let pathMap = [];
  switch (index) {
    case 1:
      if (menuItem.type == "2d") {
        if (menuItem.kind != "attachment") {
          srcList.length = 0;
          srcList.push(...menuItem.data);
          PreviewShow.value = !PreviewShow.value;
        }
      } else if (menuItem.type == "3d") {
        modelUrlArr.length = 0;
        modelUrlArr.push(...menuItem.data);
        STLbrowseShow.value = !STLbrowseShow.value;
      }
      break;
    case 2:
      pathMap.length = 0;
      menuItem.data.forEach((item) => {
        if (menuItem.type == "3d") {
          pathMap.push(item.src);
        } else if (menuItem.type == "2d") {
          pathMap.push(item.url);
        }
      });
      let data = {
        bucket: "cambridgeweb",
        "source-files": pathMap,
      };
      let result = await postRequest(
        "https://fc.cimagecloud.com/zip-download",
        data
      );
      if (result.code != 200) {
        ElMessage.error(result.message);
        return;
      }
      const url = result.data;
      const a = document.createElement("a");
      a.href = url;
      a.download = "订单文件";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      // 技工所下载改变订单状态
      if (PublicStores.UserInfo.UserType == 1) {
        let datas = {
          OrderID: menuItem.orderID,
          Type: "Download",
        };
        await postRequest("/dental/order/updateOrderStatus", datas);
        // 更改订单状态为已下载
        let index = tableData.findIndex(
          (item) => item.OrderID == menuItem.orderID
        );
        if (tableData[index].OrderStatus != 0) {
          tableData[index].OrderStatus = "3";
        }
      }
      break;
  }
};

// 分页
const currentPage = ref(1); // 当前页码
const pageSize = ref(10); // 每页显示数量
const total = ref(0); // 总条数，计算得出总页数
const jumpPage = ref(""); // 跳转页码

// 处理页码变化
const handlePageChange = (page) => {
  currentPage.value = page;
  getorderlist();
};

// 跳转到指定页
const jumpToPage = () => {
  const targetPage = parseInt(jumpPage.value);
  if (
    !isNaN(targetPage) &&
    targetPage > 0 &&
    targetPage <= Math.ceil(total.value / pageSize.value)
  ) {
    currentPage.value = targetPage;
    handlePageChange(targetPage);
  } else {
    ElMessage.error(t("Please enter a valid page number."));
  }
};

// 取消订单
function cancelOrder() {
  if (selectCheckData.length == 0) {
    ElMessage.warning(t("Please Select Order"));
    return;
  }
  ElMessageBox({
    title: t("Prompt"),
    message: h("p", null, [
      h("span", null, t("Do you want to cancel the selected orders?")),
    ]),
    showCancelButton: true,
    confirmButtonText: t("Yes"),
    cancelButtonText: t("No"),
    customClass: "confirmMessageBox", // 添加自定义类名
    confirmButtonClass: "confirm-button-with-icon", // 自定义确认按钮类
    cancelButtonClass: "cancel-button-with-icon", // 自定义取消按钮类
    modal: false, // 去除遮罩层
    draggable: true,
    overflow: false,
  }).then(() => {
    selectCheckData.forEach((item) => {
      let data = {
        OrderID: item.OrderID,
        Type: "Cancel",
      };
      postRequest("/dental/order/updateOrderStatus", data).then((res) => {
        if (res.code == 200) {
          ElMessage.success(res.message);
          orderTable.value.clearSelection();
          getorderlist();
        } else {
          ElMessage.error(res.message);
        }
      });
    });
  });
}

// 文件下载保存form数据
let fileSaveForm = reactive({
  filePath: "",
  checked1: "",
  name: "",
  region: "",
  type: "",
  radio1: "",
});

async function SaveFile(row) {
  if (row.fileInfo == undefined) {
    let res = await getRequest("/dental/order/getOrderFile", {
      OrderID: row.OrderID,
    });
    if (Object.keys(res.data).length === 0 && res.data.constructor === Object) {
      return;
    }
    row.path = res.data.orderInfo;
    row.fileInfo = DataProcessing(res.data);
    productImage.value = row;
  }
  // 使用split方法将字符串按'/'分割成数组
  const segments = row.path.split("/");
  // 删除数组中的最后一个元素
  segments.pop();
  // 使用join方法将数组重新组合成字符串
  const zipPath = segments.join("/") + "/";
  let data = {
    bucket: "cambridgeweb",
    "source-dir": zipPath,
  };
  let result = await postRequest(
    "https://fc.cimagecloud.com/zip-download",
    data
  );
  const url = result.data;
  const a = document.createElement("a");
  a.href = url;
  a.download = "订单文件";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  // 技工所下载改变订单状态
  if (PublicStores.UserInfo.UserType == 1) {
    let datas = {
      OrderID: row.OrderID,
      Type: "Download",
    };
    await postRequest("/dental/order/updateOrderStatus", datas);
    getorderlist();
  }
}

// 附件上传处理逻辑
let uploadInput = ref(null);
function handleUpload() {
  uploadInput.value.click();
}
async function handleClick(e, row) {
  console.log(111);
  
  PublicStores.changeLoadingShow(true);
  PublicStores.changeLoadingText(t("Loading"));
  let files = e.target.files;
  const rawFile = files[0];
  if (!rawFile) {
    ElMessage.warning(t("Please select a file"));
    return;
  }
  let uploadFileNames = "";
  // 将 FileList 转换为数组
  Array.from(files).forEach((item) => {
    uploadFileNames += item.name + ",";
  });
  let data = {
    OrderID: row.OrderID,
    Files: uploadFileNames,
  };
  let uploadUrlMap = await postRequest(
    "/dental/order/applyUploadAttachmentUrl",
    data
  );
  let successStr = "";
  for (let item of Array.from(files)) {
    let httpStatus = await putRequestFile(
      uploadUrlMap.data.url[item.name],
      item
    );
    if (httpStatus != 200) {
      ElNotification({
        title: t("Warning"),
        message: item.name + t("Upload failure"),
        type: "warning",
      });
      PublicStores.changeLoadingShow(false);
      return; // 跳过当前循环，继续下一个文件
    }
    successStr += item.name + ",";
  }
  let res = await postRequest("/dental/order/recordAttachmentPath", {
    Key: uploadUrlMap.data.key,
    Files: successStr,
  });
  if (res.code != 200) {
    ElMessage.error(res.message);
    return;
  }
  if (row.fileInfo != undefined) {
    getRequest("/dental/order/getOrderFile", { OrderID: row.OrderID }).then(
      (res) => {
        if (
          Object.keys(res.data).length === 0 &&
          res.data.constructor === Object
        ) {
          return;
        }
        row.path = res.data.orderInfo;
        row.fileInfo = DataProcessing(res.data);
        productImage.value = row;
      }
    );
  }
  PublicStores.changeLoadingShow(false);
  ElMessage.success(res.message);
}
// 邀请列表逻辑处理
let InviteData = reactive([]);
let messageTipState = ref("");
let InviteListShow = ref(false);
let sendMailShow = ref(false); // 控制内部对话框的显示状态
async function GetInviteList(params) {
  let res = await getRequest("/dental/user/getPartnerList");
  InviteData.length = 0;
  res.data.forEach((item) => {
    item.CreatedAt = item.CreatedAt.split("T")[0];
    item.ConfirmedAt = item.ConfirmedAt.split("+")[0];
    switch (item.Status) {
      case "0":
        item.state = t("Pending Acceptance");
        break;
      case "1":
        item.state = t("Received");
        break;
      case "2":
        item.state = t("Rejected");
        break;
    }
    InviteData.push(item);
  });
}
function openInviteList() {
  GetInviteList();
  messageTipState.value = 0;
  InviteListShow.value = true;
}

// 修改账户信息逻辑处理
let userInfoShow = ref(false);
let editUserForm = reactive({
  doctorName: "",
  clinic: "",
  address: "",
  phone: "",
  email: "",
  password: "*****************",
});

// 修改邮箱
let EditEmailInnerShow = ref(false);
function showEditEmailDialog() {
  EditEmailInnerShow.value = !EditEmailInnerShow.value;
}

// 修改密码
let EditPasswordInnerShow = ref(false);
function showEditPasswordDialog() {
  EditPasswordInnerShow.value = !EditPasswordInnerShow.value;
}

function editAccount() {
  let data = {
    NickName: editUserForm.doctorName,
    AddrName: editUserForm.clinic,
    Addr: editUserForm.address,
    Phone: editUserForm.phone,
  };
  postRequest("/dental/user/editUserInfo", data).then((res) => {
    if (res.code == 200) {
      ElMessage({
        message: res.message,
        type: "success",
      });
    } else {
      ElMessage({
        message: res.message,
        type: "error",
      });
    }
  });
}
// 获取用户信息
function getUserInfo() {
  userInfoShow.value = true;
  let data = {
    TargetUserID: PublicStores.UserInfo.UserID,
  };
  getRequest("dental/user/getUserInfo", data).then((res) => {
    let datas = res.data;
    editUserForm.doctorName = datas.NickName;
    editUserForm.clinic = datas.AddrName;
    editUserForm.address = datas.Addr;
    editUserForm.phone = datas.Phone;
    editUserForm.email = datas.Email;
    let newUserInfo = JSON.parse(JSON.stringify(PublicStores.getUserInfo));
    newUserInfo.NickName = datas.NickName;
    newUserInfo.Email = datas.Email;
    PublicStores.changeUserInfo(newUserInfo);
  });
}
// 注销账户
function exit() {
  PublicStores.WebSocketService.close();
  PublicStores.changeAccessToken("");
  PublicStores.changeRefreshToken("");
  PublicStores.changeUserInfo({});
  router.replace("/login");
}
// 购物车弹窗
let shoppingDialogShow = ref(false);
let productImage = ref(null); //购物车商品内容
function openShoppingCart(row) {
  if (row.fileInfo == undefined) {
    getRequest("/dental/order/getOrderFile", { OrderID: row.OrderID }).then(
      (res) => {
        if (
          Object.keys(res.data).length === 0 &&
          res.data.constructor === Object
        ) {
          return;
        }
        row.path = res.data.orderInfo;
        row.fileInfo = DataProcessing(res.data);
        productImage.value = row;
        shoppingDialogShow.value = true;
      }
    );
  }
  shoppingDialogShow.value = true;
}
async function MerchandiseDownload(productImage) {
  const url =
    "https://cambridgeweb.oss-accelerate.aliyuncs.com/" +
    encodeURIComponent(productImage.path);
  const a = document.createElement("a");
  a.href = url;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  // 技工所下载改变订单状态
  if (PublicStores.UserInfo.UserType == 1) {
    let datas = {
      OrderID: productImage.OrderID,
      Type: "Download",
    };
    await postRequest("/dental/order/updateOrderStatus", datas);
    getorderlist();
  }
}
// 列表展开渲染数据格式处理
function DataProcessing(data) {
  let result = [];
  let idCounter = 0; // 初始化计数器
  data.file.forEach((item, index) => {
    let updatedBase = [];
    let updatedbite = [];
    let updatedImplant = [];
    let updatedOrthodontic = [];
    let updatedPhoto = [];
    let updatedScreenshot = [];
    let updatedAttachment = [];
    if (!item.child) return;
    if (item.child.mesh) {
      const mesh = item.child.mesh;
      if (mesh.base) {
        updatedBase = mesh.base.map((ite) => ({
          ...ite,
          url: mesh.preview,
          type: "3d",
          kind: "base",
          id: idCounter++,
          groupId: index,
        }));
      }
      if (mesh.bite) {
        updatedbite = mesh.bite.map((ite) => ({
          ...ite,
          url: mesh.preview,
          type: "3d",
          kind: "bite",
          id: idCounter++,
          groupId: index,
        }));
      }
      if (mesh.implant) {
        updatedImplant = mesh.implant.map((ite) => ({
          ...ite,
          url: mesh.preview,
          type: "3d",
          kind: "implant",
          id: idCounter++,
          groupId: index,
        }));
      }
      if (mesh.orthodontic) {
        updatedOrthodontic = mesh.orthodontic.map((ite) => ({
          ...ite,
          url: mesh.preview,
          type: "3d",
          kind: "orthodontic",
          id: idCounter++,
          groupId: index,
        }));
      }
      result.push({
        id: idCounter++,
        type: "3d",
        kind: "3d",
        groupId: index,
        url: mesh.preview,
        orderID: data.orderID,
        name: item.name,
        data: [
          ...updatedBase,
          ...updatedbite,
          ...updatedImplant,
          ...updatedOrthodontic,
        ],
      });
    }
    if (item.child.photo) {
      updatedPhoto = item.child.photo.map((ite) => ({
        url: ite,
        type: "2d",
        kind: "photo",
        id: idCounter++,
        groupId: index,
        name: item.name,
      }));
      result.push({
        id: idCounter++,
        type: "2d",
        kind: "photo",
        groupId: index,
        orderID: data.orderID,
        url: item.child.photo[0],
        data: [...updatedPhoto],
        name: item.name,
      });
    }
    if (item.child.screenshot) {
      updatedScreenshot = item.child.screenshot.map((ite) => ({
        url: ite,
        type: "2d",
        kind: "screenshot",
        id: idCounter++,
        groupId: index,
      }));
      result.push({
        id: idCounter++,
        type: "2d",
        kind: "screenshot",
        groupId: index,
        orderID: data.orderID,
        name: item.name,
        url: item.child.screenshot[0],
        data: [...updatedScreenshot],
      });
    }
    if (item.child.attachment) {
      updatedAttachment = item.child.attachment.map((ite) => ({
        url: ite,
        type: "2d",
        kind: "attachment",
        id: idCounter++,
        groupId: index,
      }));
      result.push({
        id: idCounter++,
        type: "2d",
        kind: "attachment",
        groupId: index,
        orderID: data.orderID,
        name: item.name,
        url: item.child.attachment[0],
        data: [...updatedAttachment],
      });
    }
  });
  return result;
}

// 获取聊天记录数据
let sendContent = ref("");
function GetChatHistory(row) {
  let data = {
    OrderID: row.OrderID,
    Page: "",
    limit: "",
  };
  getRequest("/dental/order/messages", data).then((res) => {
    row.ChatHistory = res.data;
    // 滚动到最低部
    nextTick(() => {
      const contentHeight = document.getElementById("contentHeight");
      const chatContent = document.querySelector(".chatContent");
      chatContent.scrollTop = contentHeight.scrollHeight;
    });
  });
}

function sendMessage(row) {
  if (sendContent.value == "") {
    return;
  }
  let data = {
    OrderID: row.OrderID,
    Content: sendContent.value,
  };
  postRequest("/dental/order/messages", data).then((res) => {
    sendContent.value = "";
    GetChatHistory(row);
  });
}
</script>

<style lang="scss">
// 日期选择的样式
.mx-datepicker {
  width: 32px;
  height: 32px;
  opacity: 0;
  cursor: pointer;
  .mx-input {
    width: 32px;
    height: 32px;
    padding: 0;
    cursor: pointer;
  }
  .mx-icon-calendar {
    display: none !important;
  }
  .mx-icon-clear {
    display: none !important;
  }
}
.datePicker {
  margin-top: 8px;
  border-radius: 4px;
  border: 1px solid #ccd0d5;
  box-shadow: 3px 3px 12px 0px #13141540;
  .mx-calendar-header {
    button {
      color: #343638 !important;
    }
  }
  .mx-datepicker-content {
    .mx-calendar-range {
      .mx-calendar:nth-of-type(2) {
        display: none;
      }
    }
  }
  th {
    color: #6f9c29 !important;
  }
  .today {
    color: #6f9c29;
  }
  .cell:hover {
    background: #d9e7ba !important;
  }
  .in-range {
    background: #d9e7ba !important;
  }
  .hover-in-range {
    background: #d9e7ba !important;
  }
  .active {
    background: #6f9c29 !important;
  }
}
// modelPreview预览
.ModelPreview {
  width: 1440px;
  padding: 0;
  border-radius: 8px !important;
  .el-dialog__header {
    padding: 0;
    .el-dialog__title {
      display: none;
    }
    .el-dialog__headerbtn {
      width: 48px;
      height: 48px;
      right: -67px;
      background: url("/image/home/modelClose.png");
      background-size: cover;
      .el-dialog__close {
        display: none;
      }
    }
  }
}
.FullModelPreview {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  border-radius: 8px !important;
  .el-dialog__header {
    padding: 0;
    .el-dialog__title {
      display: none;
    }
    .el-dialog__headerbtn {
      width: 48px;
      height: 48px;
      right: -67px;
      background: url("/image/home/modelClose.png");
      background-size: cover;
      .el-dialog__close {
        display: none;
      }
    }
  }
}
// 2d Preview预览图
.PreviewDialog {
  width: 1440px;
  padding: 0;
  border-radius: 8px;
  .el-dialog__header {
    padding: 0;
    .el-dialog__title {
      display: none;
    }
    .el-dialog__headerbtn {
      width: 48px;
      height: 48px;
      right: -67px;
      background: url("/image/home/modelClose.png");
      background-size: cover;
      .el-dialog__close {
        display: none;
      }
    }
  }
}
.shoppingDialog {
  width: 1200px;
  padding: 0;
  border-radius: 8px;
  .el-dialog__header {
    padding: 0;
    .el-dialog__title {
      display: none;
    }
    .el-dialog__headerbtn {
      width: 48px;
      height: 48px;
      right: -67px;
      background: url("/image/home/modelClose.png");
      background-size: cover;
      .el-dialog__close {
        display: none;
      }
    }
  }
  .shoppingDialogContent {
    height: 680px;
    background: url("/image/home/empty.png");
    background-size: contain !important;
    border-bottom: 1px solid #ccd0d5;
    box-sizing: border-box;
  }
  .el-dialog__footer {
    height: 120px;
    .el-button {
      width: 260px;
      height: 56px;
      img {
        width: 32px;
        height: 32px;
        margin-right: 20px;
      }
    }
  }
}
// 修改账户信息框
.editAccountInfo {
  width: 1120px;
  padding: 0;
  border: 1px solid #ccd0d5;
  box-shadow: 4px 4px 16px 0px #13141540;
  border-radius: 8px;
  .el-dialog__header {
    margin-right: 0px;
    height: 80px;
    padding: 0px;
    border-bottom: 1px solid #ccd0d5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    .el-dialog__title {
      margin-left: 72px;
      font-size: 24px;
      font-weight: 700;
    }
    .el-dialog__headerbtn {
      position: static !important; /* 强制取消绝对定位 */
      margin-top: 12px;
      margin-right: 40px;
      color: #000;
      background: url("/image/home/accountClose.png");
      background-size: cover !important;
      i {
        display: none; // 在这里隐藏弹窗默认的关闭图标
      }
    }
  }
  .el-dialog__body {
    padding: 48px 72px 0;
    .editAccountInfoContent {
      padding: 48px 48px 260px;
      border: 1px solid #ccd0d5;
      background: #f9fbfd;
      border-radius: 8px;
      .el-form-item {
        width: 408px;
        margin-right: 20px;
        margin-bottom: 32px;
        .el-input__wrapper {
          height: 40px;
        }
        .el-form-item__label {
          width: 108px;
          line-height: 40px;
          height: 40px;
        }
      }
      .editIcon {
        width: 40px;
        height: 40px;
        cursor: pointer;
        background: url("/image/home/accountEdit.png");
        background-size: 40px;
      }
      .el-form-item:nth-of-type(5) .el-input__wrapper,
      .el-form-item:nth-of-type(6) .el-input__wrapper {
        background: transparent !important;
        box-shadow: none !important;
        padding-right: 0px !important;
      }
    }
  }
  .el-dialog__footer {
    height: 180px;
    .el-button:nth-last-of-type(2) {
      width: 260px;
      height: 56px;
      img {
        width: 40px;
        height: 40px;
        margin-right: 18px;
      }
    }
    .el-button:nth-last-of-type(1) {
      width: 260px;
      height: 56px;
      margin-left: 40px;
      img {
        width: 40px;
        height: 40px;
        margin-right: 18px;
      }
    }
  }
}
// 邀请列表dialog
.InviteListDialog {
  width: 1120px;
  padding: 0;
  box-shadow: 4px 4px 16px 0px #13141540;
  border-radius: 8px;

  .el-dialog__title {
    font-weight: 600;
    font-size: 24px;
    color: #343638;
    margin-left: 72px;
  }
  .el-dialog__header {
    padding: 0;
    border-bottom: 1px solid #ccd0d5;
    box-sizing: border-box;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-dialog__headerbtn {
      position: static !important; /* 强制取消绝对定位 */
      margin-top: 12px;
      margin-right: 40px;
      color: #000;
      background: url("/image/home/dialogClose.png");
      background-size: cover;
      i {
        display: none; // 在这里隐藏弹窗默认的关闭图标
      }
    }
  }

  :deep(.el-dialog__body) {
    border-radius: 20px;
  }
  .InviteCard:hover {
    background: #f5f5f5;
  }
}
// 文件下载dialog
.fileSaveDialog {
  width: 1120px;
  padding: 0;
  border-radius: 8px;
  border: 1px solid #ccd0d5;
  box-shadow: 4px 4px 16px 0px #13141540;
  .el-dialog__header {
    margin-right: 0px;
    height: 80px;
    padding: 0px;
    border-bottom: 1px solid #ccd0d5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    .el-dialog__title {
      margin-left: 72px;
      font-size: 24px;
      font-weight: 700;
    }
    .el-dialog__headerbtn {
      position: static !important; /* 强制取消绝对定位 */
      margin-top: 12px;
      margin-right: 40px;
      color: #000;
      background: url("/image/home/accountClose.png");
      background-size: cover;
      i {
        display: none; // 在这里隐藏弹窗默认的关闭图标
      }
    }
  }
  .fileSave {
    width: 100%;
    padding: 48px 72px 0;
    box-sizing: border-box;
    .fileSaveForm {
      width: 100%;
      background: #f9fbfd;
      box-sizing: border-box;
      padding: 48px 120px 116px 64px;
      border: 1px solid #ccd0d5;
      border-radius: 8px;
      .el-form-item__label {
        width: auto;
        font-size: 18px;
        color: #343638;
        font-weight: 600;
        margin-bottom: 32px;
      }
      .el-form-item {
        margin-bottom: 24px;
        // input框
        .DirectoryInput {
          width: 640px;
          height: 40px;
          margin-right: 24px;
        }
        .selectPathBtn {
          width: 120px;
          height: 40px;
        }
        // 正方形复选框默认和选中
        .el-checkbox {
          height: 24px;
          .el-checkbox__label {
            padding-left: 16px;
          }
        }
        .el-checkbox__inner {
          //大小
          width: 24px;
          height: 24px;
          border-radius: 2px;
        }
        .el-checkbox__input.is-checked .el-checkbox__inner::after {
          //对钩的位置
          top: 4px;
          left: 9px;
          height: 9px;
          width: 4px;
          border-width: 2px;
        }
        // 圆形单选框默认和选中
        .el-radio {
          height: 28px;
          .el-radio__label {
            padding-left: 16px;
          }
        }
        .el-radio__inner {
          width: 24px;
          height: 24px;
        }
        .el-radio__inner::after {
          width: 12px;
          height: 12px;
        }
      }
      .el-form-item:nth-of-type(2) {
        margin-bottom: 16px;
      }
      .el-form-item:nth-of-type(3) {
        margin-bottom: 64px;
      }
    }
  }
  .el-dialog__footer {
    height: 180px;
    .el-row {
      .el-button:nth-of-type(1) {
        width: 260px;
        height: 56px;
        img {
          width: 40px;
          height: 40px;
          margin-right: 18px;
        }
      }
      .el-button:nth-of-type(2) {
        width: 260px;
        height: 56px;
        margin-left: 40px;
        img {
          width: 40px;
          height: 40px;
          margin-right: 18px;
        }
      }
    }
  }
}
// 订单表单列表
.orderTable {
  font-size: 16px;
  .el-table__header {
    height: 48px;
  }
  .OrderStatusIcon {
    width: 24px;
    height: 24px;
  }
  .messageTip {
    .el-badge__content {
      top: 0;
      width: 24px;
      height: 24px;
    }
    .el-badge__content--danger {
      border-radius: 50px;
    }
  }
  .el-scrollbar__wrap {
    border-left: 1px solid #ccd0d5;
    border-right: 1px solid #ccd0d5;
  }
  .el-table__inner-wrapper::before {
    background: #ccd0d5;
  }
  .el-table-column--selection > .cell {
    height: 24px;
  }
  .el-table-column--selection > .cell .el-checkbox__inner {
    width: 24px;
    height: 24px;
  }
  .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
    //对钩的位置
    top: 8px;
    height: 4px;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner::after {
    //对钩的位置
    top: 4px;
    left: 9px;
    height: 9px;
    width: 4px;
    border-width: 2px;
  }

  .el-table__row {
    height: 56px;
    td {
      border-color: #ccd0d5;
    }
  }
  .expandBox {
    width: 100%;
    height: 360px;
    display: flex;
    border-bottom: 1px solid #c9cfd8;
    background: #f3f4f7;
    .thumbnailBox {
      width: 1128px;
      height: 100%;
      padding: 12px 0 12px 0;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
      border-right: 1px solid #c9cfd8;
      box-sizing: border-box;
      .thumbnailCard {
        /* 首先将所有 .thumbnailCard 的 margin-left 设置为 28px */
        max-width: 156px;
        height: 158px;
        margin-left: 28px;
        margin-top: 8px;
        .symbol {
          width: 60px;
          height: 24px;
          background: url("/image/home/2dSymbol.png") no-repeat;
          background-size: cover;
        }
        .symbol3D {
          width: 60px;
          height: 24px;
          background: url("/image/home/3dSymbol.png") no-repeat;
          background-size: cover;
        }
        .screenshot {
          width: 60px;
          height: 24px;
          background: url("/image/home/screenshot.png") no-repeat;
          background-size: cover;
        }
        .photo {
          width: 60px;
          height: 24px;
          background: url("/image/home/photo.png") no-repeat;
          background-size: cover;
        }
        .attachment {
          width: 60px;
          height: 24px;
          background: url("/image/home/attachment.png") no-repeat;
          background-size: cover;
        }
        .picture {
          width: 156px;
          height: 112px;
          border: 1px solid #ccd0d5;
          box-sizing: border-box;
          border-radius: 8px;
          background-size: contain !important;
          background-color: #fff !important;
        }
        .pictureActive {
          border: 3px solid #6f9c29;
        }
        p {
          line-height: 20px;
          font-size: 13px;
        }
        /* 第一个 <p> 标签 */
        p:first-of-type {
          margin: 6px 0 0 8px;
        }

        /* 第二个 <p> 标签 */
        p:nth-of-type(2) {
          margin: 0 0 0 8px;
        }
      }
    }
    .chatBox {
      width: 696px;
      height: 100%;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #c9cfd8;
      .chatHeader {
        height: 40px;
        font-weight: 700;
        background: #f3f4f7;
        border-bottom: 1px solid #c9cfd8;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .chatContent {
        background: #e9eaee;
        flex: 1;
        overflow: auto;
        padding: 12px 24px;
        .chatFragment {
          margin-bottom: 12px;
          .sendTime {
            height: 20px;
            cursor: default;
            opacity: 0 !important;
          }
          .targetChat {
            display: flex;
            .el-avatar {
              width: 32px;
              height: 32px;
              margin-right: 12px;
            }
            .Content {
              max-width: 320px;
              border-radius: 0 8px 8px 8px;
              padding: 12px 16px;
              box-sizing: border-box;
            }
          }
          .MyChat {
            display: flex;
            flex-direction: row-reverse;
            justify-content: end;
            .el-avatar {
              width: 32px;
              height: 32px;
              margin-left: 12px;
            }
            .Content {
              max-width: 320px;
              border-radius: 8px 0px 8px 8px;
              padding: 12px 16px;
              box-sizing: border-box;
            }
          }
          &:hover .sendTime {
            opacity: 1 !important;
            cursor: default;
          }
        }
      }
      .chatFooter {
        height: 40px;
        border-top: 1px solid #c9cfd8;
        .sendInput {
          height: 40px;
          .el-input__wrapper {
            box-shadow: none;
            border-radius: 0px;
          }
        }
      }
    }
  }
  .el-table__expanded-cell {
    padding: 0;
  }
  .highlight-row {
    td {
      background: #d9e7ba !important;
    }
  }
  .el-table__empty-block {
    height: 100%; /* 确保高度继承自父元素，而不是撑开 */
  }
  .el-table__header-wrapper {
    border-radius: 4px 4px 0 0;
    border: 1px solid #6f9c29;
    box-sizing: border-box;
    .el-table__header {
      .el-table__cell {
        border: 0;
      }
    }
  }
  .current-row {
    background: #d9e7ba;
  }
  .el-table__expand-column .cell {
    .el-table__expand-icon--expanded {
      // 这是点击后的旋转角度
      transform: rotate(180deg) !important;
    }
    .el-table__expand-icon > .el-icon {
      display: none !important;
    }
    .el-table__expand-icon {
      content: url("/image/home/downArrow.png") !important;
      transform: rotate(0deg);
    }
  }
}
// 悬停用户头像弹窗
.userinfoPopover {
  width: 240px !important;
  margin-left: 48px;
  border: 1px solid #ccd0d5 !important;
  box-shadow: 3px 3px 12px 0px #13141540 !important;
  border-radius: 4px !important;
  .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 21px;
    .el-avatar {
      width: 48px;
      height: 48px;
      margin-bottom: 8px;
    }
    .NickName {
      margin-bottom: 2px;
      font-weight: 600;
      font-size: 16px;
      color: #494c4f;
    }
  }
  .accountSettingBtn {
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    img {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }
  .signOutBtn {
    width: 100%;
    height: 40px;
    margin-left: 0;
    margin-bottom: 8px;
    img {
      width: 24px;
      height: 24px;
      margin-right: 24px;
    }
    span {
      letter-spacing: 4px;
    }
  }
}
//右键自定义菜单
.custom-context-menu {
  width: 200px;
  position: absolute;
  z-index: 1000;
  background: white;
  border: 1px solid #c9cfd8;
  box-shadow: 3px 3px 12px 0px #13141540;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 4px 0 6px 0;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      height: 40px;
      line-height: 40px;
      padding: 0 24px;
      cursor: pointer;
    }
    li:hover {
      background: #d9e7ba;
    }
  }
}
.is-message-box {
  display: flex; /* 使用 Flex 布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.confirmMessageBox {
  padding: 0;
  width: 400px;
  height: 240px;
  box-shadow: 4px 4px 16px 0px #13141540;
  display: flex;
  flex-direction: column;
  .el-message-box__header {
    padding: 14px 24px;
    box-sizing: border-box;
    border-bottom: 1px solid #ccd0d5;
    font-weight: 700;

    .el-message-box__title ::before {
      width: 20px;
      height: 20px;
      content: url("/image/home/warn.png"); /* 替换为图标的路径 */
      margin-right: 16px; /* 添加与文本的间隔 */
      vertical-align: middle;
    }
    .el-message-box__headerbtn {
      display: none;
    }
  }
  .el-message-box__container {
    padding: 18px 32px;
    font-size: 18px;
    line-height: 27px;
  }
  .el-message-box__btns {
    flex-grow: 1;
    align-items: end;
    padding-bottom: 24px;
    padding-right: 24px;
  }
  .cancel-button-with-icon {
    width: 96px;
    height: 48px;
    background: #f3f4f7;
  }
  .cancel-button-with-icon ::before {
    width: 32px;
    height: 32px;
    background: url("/image/home/Close .png"); /* 替换为图标的路径 */
    background-size: 32px;
    margin-right: 8px; /* 添加与文本的间隔 */
    content: "";
  }
  .confirm-button-with-icon {
    width: 96px;
    height: 48px;
    background: #6f9c29;
  }
  .confirm-button-with-icon ::before {
    width: 32px;
    height: 32px;
    background: url("/image/home/Confirm.png"); /* 替换为图标的路径 */
    background-size: 32px;
    margin-right: 8px; /* 添加与文本的间隔 */
    content: "";
  }
}
</style>
<style scoped lang="scss">
.upload-input {
  display: none;
  z-index: -9999;
}
.el-container {
  width: 100%;
  height: 100%;
  .el-header {
    height: 64px;
    .logo {
      width: 250px;
      height: 47px;
      background: url("/image/home/logo.png") no-repeat;
      background-size: cover;
    }
    .el-space {
      gap: 0px 24px !important;
    }
    .avatarBox {
      width: 160px;
      height: 40px;
      padding: 10px 8px;
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .el-avatar {
        width: 32px !important;
        height: 32px !important;
      }
      span {
        width: 80px;
        margin-left: 16px;
      }
    }
    .IcomBox {
      width: 152px;
      display: flex;
      justify-content: space-between;
    }
  }
  .el-main {
    padding: 24px;
    background: #f0f3f6;
    box-sizing: border-box;
    .main {
      width: 100%;
      height: 100%;
      padding: 24px;
      padding-top: 0;
      padding-bottom: 0;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 3px 3px 12px 0px #13141540;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      .headerContent {
        width: 100%;
        height: 96px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .searchBox {
          width: 478px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .el-input {
            width: 400px;
            height: 40px;
            img {
              width: 24px;
              height: 24px;
            }
          }
        }
        .featureIcon {
          width: 72px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .cancelOrderBtn {
          min-width: 112px;
          height: 40px;
          background: #f3f4f7;
          img {
            width: 24px;
            height: 24px;
            margin-right: 4px;
          }
        }
      }

      .pagination-container {
        height: 112px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        :deep(.el-pagination) {
          .is-first {
            background: #f3f4f7;
            border-radius: 4pxp;
            border: 1px solid #ccd0d5;
          }
          .is-last {
            background: #f3f4f7;
            border-radius: 4px;
            border: 1px solid #ccd0d5;
          }
        }
        .el-input {
          width: 64px;
          height: 32px;
        }
        .el-button {
          min-width: 80px;
          height: 32px;
          background: #f3f4f7;
          font-size: 14px;
        }
      }

      .pagination-container span:nth-child(1) {
        margin-right: 60px;
      }
      .pagination-container span:nth-child(3) {
        margin-left: 60px;
        margin-right: 16px;
      }
      .pagination-container span:nth-child(5) {
        margin: 16px;
      }
      h3 {
        font-family: Source Han Sans CN;
        font-size: 24px;
        font-weight: 700;
        line-height: 22px;
      }
    }
  }
}
</style>
